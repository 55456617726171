import React from 'react'
import KidsComp from '../../components/KidsComp/KidsComp'

const Kids = () => {
  return (
    <div>
      
      <KidsComp/>
    </div>
  )
}

export default Kids