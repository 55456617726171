import React from 'react'
import LoginComp from '../../components/LoginComp/LoginComp'

const Login = () => {
  return (
    <div>
      <LoginComp/>
    </div>
  )
}

export default Login