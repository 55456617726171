import React from 'react'
import WomanComp from '../../components/WomanComp/WomanComp'

const Woman = () => {
  return (
    <div>
      <WomanComp/>
    </div>
  )
}

export default Woman