import React from 'react'
import CardComp from '../../components/CardComp/CardComp'

const Card = () => {
  return (
    <div>Card

      <CardComp/>
    </div>
  )
}

export default Card