import React from 'react'
import ManComp from '../../components/ManComp/ManComp'

const Man = () => {
  return (
    <div>
      <ManComp/>
    </div>
  )
}

export default Man